export enum SearchSpringFacets {
  'BRAND' = 'tags_brand',
  'CATEGORY' = 'tags_category',
  'COLOR' = 'tags_color',
  'LENGTH' = 'tags_length',
  'OCCASION' = 'tags_occasion',
  'SEASON' = 'tags_season',
  'SLEEVES' = 'tags_sleeves',
  'SIZE' = 'tags_size',
  'PRICE' = 'ss_price',
  'TREND' = 'tags_trend',
  'PCT' = 'ss_instock_pct',
}
