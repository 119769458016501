import { create } from 'zustand';
import { createJSONStorage, persist, subscribeWithSelector } from 'zustand/middleware';
import { RecentlyViewedProduct } from '@ui/types/contextObjects';
import { useEffect, useState } from 'react';
import search from '@ui/axios/searchSpring/search';
import { GetProductsPricing } from '@client-shopify/gql/storefront/api/queries';
import env from '@ui/env';
import { getCustomerCountry } from '@ui/hooks/useCustomerCountry';

interface RecentlyViewedState {
  timeStamp: Date;
  recentlyViewed: Record<string, RecentlyViewedProduct | undefined>;
  setRecentlyViewed: (id: string, args: GenerateProductArgs) => void;
  resetRecentlyViewedIfStale: () => void;
  updateRecentlyViewed: (id: string) => void;
  getValidRecentlyViewed: () => Promise<RecentlyViewedProduct[]>;
}

const isImageUrlValid = async (imageUrl: string) => {
  try {
    const response = await fetch(imageUrl, { method: 'HEAD' });
    return response.ok;
  } catch {
    return false;
  }
};

interface GenerateProductArgs {
  ssUserIdCookie: string;
  ssSessionIdNamespaceCookie: string;
  pageLoadIdCookie: string;
  cartCookie: string;
  lastViewedCookie: string;
  shopperCookie: string;
  product: string;
}

const generateProductData = async (args: GenerateProductArgs) => {
  return await search({
    ssUserId: args.ssUserIdCookie,
    ssSessionIdNamespace: args.ssSessionIdNamespaceCookie,
    pageLoadId: args.pageLoadIdCookie,
    shopper: args.shopperCookie,
    cart: args.cartCookie,
    lastViewed: args.lastViewedCookie,
    query: args.product,
  });
};

const useRecentlyViewedStore = create<RecentlyViewedState>()(
  subscribeWithSelector(
    persist(
      (set, get) => ({
        timeStamp: new Date(),
        recentlyViewed: {},
        setRecentlyViewed: async (id, generateProductDataArgsargs) => {
          if (!id) return;
          const {
            data: { results },
          } = await generateProductData(generateProductDataArgsargs);
          set((state) => {
            if (Object.keys(state.recentlyViewed).length >= 10) {
              return (state.recentlyViewed = {});
            }
            if (Object.keys(state.recentlyViewed).includes(id)) {
              return state.recentlyViewed;
            }
            if (results[0] === undefined) return state.recentlyViewed;
            return {
              recentlyViewed: {
                ...state.recentlyViewed,
                [id]: {
                  metafields: results[0].metafields,
                  title: results[0].title,
                  variantSizes: [results[0].ss_sizes],
                  brand: results[0].brand,
                  discountedPrice: results[0].price,
                  handle: results[0].handle,
                  imageUrl: results[0].imageUrl,
                  price: results[0].price,
                  ss_sizes: results[0].ss_sizes,
                  ssId: results[0].ss_id,
                  ssSku: results[0].sku,
                  ssUid: results[0].variant_product_id,
                  ssVariantSkus: results[0].variant_sku,
                  published_at: results[0].published_at,
                },
              },
            };
          });
        },
        getValidRecentlyViewed: async () => {
          get().resetRecentlyViewedIfStale();
          const allProducts = Object.values(get().recentlyViewed);
          const validProducts = await Promise.all(allProducts.filter((p) => isImageUrlValid(p?.imageUrl as string)));
          return validProducts as RecentlyViewedProduct[];
        },
        resetRecentlyViewedIfStale: () => {
          set((state) => {
            const currentTime = new Date().getTime();
            const timeElapsed = currentTime - new Date(state.timeStamp).getTime();
            const oneDayInMilliseconds = 1000 * 60 * 60 * 24;

            if (timeElapsed >= oneDayInMilliseconds) {
              return { recentlyViewed: {}, timeStamp: new Date() };
            }
            return state;
          });
        },
        updateRecentlyViewed: (id) =>
          set((state) => {
            const newState = { ...state.recentlyViewed };
            delete newState[id];
            return { recentlyViewed: newState };
          }),
      }),
      {
        name: 'recentlyViewed',
        storage: createJSONStorage(() => localStorage),
      },
    ),
  ),
);

// an abstraction so that valid products can be referenced synchronously
export const useValidProducts = () => {
  const { getValidRecentlyViewed } = useRecentlyViewedStore();
  const [returnProducts, setReturnProducts] = useState<RecentlyViewedProduct[]>();

  useEffect(() => {
    (async () => {
      const validProducts = await getValidRecentlyViewed();

      if (env.MULTICURRENCY_FEATURE && validProducts.length > 0) {
        const productsPricing = await GetProductsPricing({
          first: validProducts.length,
          query: validProducts.map((p) => `id:${p.ssUid}`).join(' OR '),
          country: getCustomerCountry(),
        });

        validProducts.forEach((product) => {
          const productEdges = productsPricing.data?.products.edges || [];
          const productPrice = productEdges.find((p) => p.node.id.split('/').pop() === product.ssUid);
          if (!productPrice) return;
          product.price = productPrice.node.priceRange.maxVariantPrice.amount;
          product.currency = productPrice.node.priceRange.maxVariantPrice.currencyCode;
          product.variant_compare_at_price = productPrice.node.compareAtPriceRange.maxVariantPrice.amount;
        });
      }

      setReturnProducts(validProducts);
    })();
  }, [getValidRecentlyViewed]);

  return returnProducts?.map((product) => ({
    ...product,
    tags: [], // @todo add tags in recently viewed
  }));
};

export default useRecentlyViewedStore;
