import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import beacon from '@ui/axios/searchSpring/beacon';
import { Carousel, CarouselProps, LeftChevronIcon, RightChevronIcon } from '@ui/components/core';
import ProductCard, { ProductCardProps } from '../../cards/ProductCard/ProductCard';
import cn from '@ui/utils/cn';

type align = 'center' | 'start' | 'end';

type ProductCardCarouselProps = {
  collection?: string;
  carousel: { tag: string; placement: string; products: Array<any> };
  height: number;
  width: number;
  align?: align | undefined;
  loop?: boolean;
  className?: string;
  classNames?: CarouselProps['classNames'];
  withControls?: boolean;
  draggable?: boolean;
  layout?: ProductCardProps['layout'];
};

const ProductCardCarousel = ({
  collection,
  carousel,
  height,
  width,
  align,
  loop,
  className,
  classNames,
  withControls = false,
  draggable,
  layout,
}: ProductCardCarouselProps): React.ReactElement => {
  const [carouselViewEventFired, setCarouselEventFired] = React.useState<boolean>(false);
  const [inViewProducts, setInViewProducts] = React.useState<Array<string>>([]);
  const [viewedProducts, setViewedProducts] = React.useState<string[]>([]);
  const addViewedProducts = React.useCallback(
    (product: string) => {
      if (!viewedProducts.includes(product)) {
        setViewedProducts((prevProducts) => [...prevProducts, product]);
      }
    },
    [viewedProducts],
  );

  const [ssBeacon, setSSBeacon] = React.useState<
    | {
        id: string;
        tag: string;
        placement: string;
      }
    | undefined
  >(undefined);

  const [isIntersecting, setIsIntersecting] = React.useState(false);

  const ref = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting));

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  React.useEffect(() => {
    if (carousel.tag && isIntersecting && !ssBeacon) {
      const id = uuidv4();

      setSSBeacon({
        id,
        tag: carousel.tag,
        placement: carousel.placement,
      });
    }
  }, [ssBeacon, carousel, isIntersecting]);

  React.useEffect(() => {
    if (!carouselViewEventFired && isIntersecting && ssBeacon && inViewProducts.length > 0) {
      beacon({
        type: 'profile.render',
        id: ssBeacon.id,
        tag: ssBeacon.tag,
        placement: ssBeacon.placement,
      }).then(() => {
        beacon({
          type: 'profile.impression',
          id: ssBeacon.id,
          tag: ssBeacon.tag,
          placement: ssBeacon.placement,
        });
      });

      beacon({
        type: 'profile.product.render',
        id: uuidv4(),
        pid: ssBeacon.id,
        tag: ssBeacon.tag,
        placement: ssBeacon.placement,
        skus: inViewProducts,
      }).then(() => {
        beacon({
          type: 'profile.product.impression',
          id: uuidv4(),
          pid: ssBeacon.id,
          tag: ssBeacon.tag,
          placement: ssBeacon.placement,
          skus: inViewProducts,
        });
      });

      setCarouselEventFired(true);
    }
  }, [carouselViewEventFired, inViewProducts, isIntersecting, ssBeacon]);

  React.useEffect(() => {
    if (!isIntersecting) {
      setCarouselEventFired(false);
      setInViewProducts([]);
    }
  }, [isIntersecting]);

  return (
    <Carousel
      ref={ref}
      slideSize={width}
      align={align}
      withControls={withControls}
      draggable={draggable}
      nextControlIcon={<RightChevronIcon className="absolute top-0 h-full bg-white" height={24} width={24} />}
      previousControlIcon={<LeftChevronIcon className="absolute top-0 h-full bg-white" height={24} width={24} />}
      controlSize={25}
      loop={loop}
      className={className}
      classNames={{
        ...classNames,
        controls: 'hidden md:flex',
        control: cn('bg-white h-full relative active:scale-100', 'first:pr-3 last:pl-3', classNames?.control),
        slide: cn('mx-2 basis-auto', classNames?.slide),
      }}
      containScroll="trimSnaps"
    >
      {carousel.products.map((product) => (
        <Carousel.Slide key={product.handle} data-carousel-item={`carousel-item-${product.handle}`}>
          <div className="w-full">
            <ProductCard
              draggable={false}
              addViewedProducts={addViewedProducts}
              ssBeacon={ssBeacon}
              collection={collection}
              product={product}
              href={
                collection ? `/collections/${collection}/products/${product.handle}/` : `/products/${product.handle}/`
              }
              height={height}
              width={width}
              layout={layout}
            />
          </div>
        </Carousel.Slide>
      ))}
    </Carousel>
  );
};

ProductCardCarousel.defaultProps = {
  collection: undefined,
  align: 'start',
  loop: false,
  className: undefined,
  withControls: false,
};

export default ProductCardCarousel;
