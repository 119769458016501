import { authSelectors } from '@ui/store/authStore';
import router, { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useGetCart } from './useCartQuery';
import { useCartStore } from '@ui/store.export';

// Add type definitions

type ScarabTrackingProps = {
  productId?: string;
  collectionName?: string;
  searchTerm?: string;
};

// Separate utility function
const extractIdFromGid = (gid: string): string => gid.split('/').pop() || '';

export default function useScarabTracking({ productId, collectionName, searchTerm }: ScarabTrackingProps) {
  const router = useRouter();
  const auth = authSelectors.auth;
  const customerEmail = auth.use.customer()?.customer?.email || '';
  const cartID = useCartStore((state) => state?.cartId);
  const cartUpdatedKey = useCartStore((state) => state?.cartUpdatedKey);
  const { data: getCartQueryResults, isLoading } = useGetCart({
    refreshKey: cartUpdatedKey || '',
    cartId: cartID || '',
  });

  useEffect(() => {
    if (isLoading || !window.ScarabQueue) return;
    try {
      const formattedCollection = collectionName
        ? collectionName
            .split('-')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
        : '';

      const cartItems =
        getCartQueryResults?.cart?.cartLines?.map((item) => ({
          item: extractIdFromGid(item.variantId),
          quantity: item.quantity,
          price: Number(item.costPerQuantity.amount) * item.quantity,
        })) || [];
      // Group related tracking calls
      if (productId) window.ScarabQueue.push(['view', `g/${extractIdFromGid(productId)}`]);
      if (formattedCollection) window.ScarabQueue.push(['category', formattedCollection]);
      if (customerEmail) window.ScarabQueue.push(['setEmail', customerEmail]);

      if (cartID)
        window.ScarabQueue.push(['tag', cartID.replaceAll(':', '%3A').replaceAll('/', '%2F').replaceAll('?', '%3F')]);

      if (cartItems) window.ScarabQueue.push(['cart', cartItems]);
      if (searchTerm) window.ScarabQueue.push(['searchTerm', searchTerm]);
      window.ScarabQueue.push(['go']);
    } catch (error) {}
  }, [router.asPath, isLoading, getCartQueryResults, customerEmail, productId, collectionName, cartID, searchTerm]);
}
